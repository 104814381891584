import {
  createComponentAdmin,
  type SchemaTypeHelper,
} from '@backstage-components/base';
import {
  defaultFieldData as stackDefaultFieldData,
  schema as stackSchema,
  uiSchema as stackUiSchema,
} from '@backstage-components/stack-layout';
import {Type, type Static} from '@sinclair/typebox';

const reactName = 'CollectionList';
const name = reactName;
const description = reactName;

export const stackUiSchemaWithoutTabs = Object.fromEntries(
  Object.entries(stackUiSchema).filter(([k, _v]) => k !== 'ui:groups')
);

const schema = Type.Object(
  {
    useLayout: Type.Boolean({
      title: 'Use Layout',
      default: false,
    }),
    collection: Type.String({title: 'Collection Source!'}),
    instanceName: Type.String({title: 'Instance Name'}),
  },
  {
    dependencies: {
      useLayout: {
        oneOf: [
          {properties: {useLayout: {enum: [true]}, stackProps: stackSchema}},
          {properties: {useLayout: {enum: [false]}}},
        ],
      },
    },
  }
);

const uiSchema = {
  'ui:groups': {
    'ui:template': 'tabs',
    sections: [
      [
        'Properties',
        [
          {
            'ui:template': 'accordion',
            sections: [
              [
                'CollectionList Properties',
                ['useLayout', 'collection', 'instanceName'],
              ],
              ['Stack Layout', ['stackProps']],
            ],
          },
        ],
      ],
      [
        'Styling',
        [
          {
            'ui:template': 'accordion',
            sections: [['Custom Styles', ['styleAttr']]],
          },
        ],
      ],
      [
        'Animations',
        [
          {
            'ui:template': 'accordion',
            sections: [['Animations', ['animationStates']]],
          },
        ],
      ],
    ],
  },
  stackProps: stackUiSchemaWithoutTabs,
  collection: {
    'ui:widget': 'CollectionSelectWidget',
  },
};

const defaultFieldData: Static<typeof schema> & {
  stackProps: Static<typeof stackSchema>;
} = {
  useLayout: false,
  collection: '',
  instanceName: '',
  // ? how to handle the default stack props, as they are optional as a whole
  stackProps: stackDefaultFieldData,
};

export const ComponentDefinition = createComponentAdmin({
  id: 'c2eeaca5-6ff2-49ba-8f63-2e2d08be7c6b',
  reactName,
  name,
  category: 'collections',
  description,
  defaultFieldData,
  instructions: Type.Union([]),
  schema,
  slotConfiguration: {
    items: {maxModules: 100, displayWidth: 12, title: 'Modules'},
  },
  positionRestrictions: {
    disallowedDescendants: [reactName],
    disallowedAncestors: [reactName],
  },
  slug: reactName,
  uiSchema,
  version: 1,
})
  .withAnimationStates()
  .withStyles()
  .build();

export type SchemaType = SchemaTypeHelper<typeof ComponentDefinition>;
